import React, { useState } from "react";
import "./App.css";
import Home from "./Home";
import Footer from "./Footer";
import Accessories from "./Accessories.jsx";
import About from "./About.jsx";
import Contact from "./Contact.jsx";
import Navbar from "./Navbar";
import BoxProfile from "./BoxProfile.jsx";
import Corrugated from "./Corrugated.jsx";
import BrickTile from "./BrickTile.jsx";
import Orientile from "./Orientile.jsx";
import Elegantile from "./Elegantile.jsx";
import Decra from "./Decra.jsx";

import EmailForm from './EmailForm.jsx';


// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import { Router, Route, Routes } from "react-router-dom";

function App() {


  return (
    <div className="App">
<Navbar/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/acessories" element={<Accessories />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/boxprofile" element={<BoxProfile />} />
        <Route path="/corrugated" element={<Corrugated/>} />
        <Route path="/bricktile" element={<BrickTile/>} />
        <Route path="/orientile" element={<Orientile/>} />
        <Route path="/elegantile" element={<Elegantile/>} />
        <Route path="/decra" element={<Decra/>} />
        <Route path="/email" element={<EmailForm/>} />



      </Routes>


      <Footer />

    </div>
  );
}

export default App;
library.add(fab, fas, far);
