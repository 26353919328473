import React from "react";
import Home from "./mabatu.webp";
import { motion } from "framer-motion";
import Slider from "./slider";
import { Link } from "react-router-dom"; // Import the Link component

function About() {
  const homeStyle = {
    backgroundImage: `url(${Home})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    filter: "brightness(100%)",
  };

  const text = " Discover the Heart and Steel ".split(" ");
  const text1 = "  Behind Imarikah Roofing solutions".split(" ");

  return (
    <div>
      {/* About Us */}
      <div>
        <div className="font-Nunito">
          <div className=" h-[600px] py-10 p-6 pt-12" style={homeStyle}>
            {text.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: i / 10,
                }}
                key={i}
                className="text-4xl font-semibold "
              >
                {el}{" "}
              </motion.span>
            ))}
            <br />
            {text1.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: i / 10,
                }}
                key={i}
                className="text-4xl font-semibold "
              >
                {el}{" "}
              </motion.span>
            ))}
            {/* <h1 className=" md:p-24 pl-4 text-4xl font-semibold ">Discover the Heart and Steel <br/> Behind Imarikah Roofing Solutions.</h1> */}
          </div>
           {/* About Us */}
      <div className="md:flex md:flex-row space-y-8 md:space-y-0 md:space-x-8 md:m-6">
        {/* About Us div */}
        <div className="md:w-1/2 md:p-6">
          <motion.div whileHover={{ scale: 1.1 }} className="relative">
            <h2 className="uppercase md:tracking-wide text-3xl text-center font-semibold">
              Introducing Imarikah Roofing Solutions
            </h2>
            <motion.div
              className="absolute left-0 right-0 bottom-0 h-0.5 bg-red-600"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0.3 }}
            ></motion.div>
          </motion.div>

          <p className="mt-2 text-center text-black text-xl pt-4">
            "Imarikah Roofing Solutions is a leading provider of high-quality
            iron sheet solutions, catering to both residential and commercial
            construction needs. With a rich legacy of excellence, we have been a
            cornerstone in the industry, delivering durable and versatile iron
            sheets designed to withstand the test of time and the harshest
            weather conditions.
            <br />
            Imarikah roofing sheets come  with panels and ridges, which contribute to their solid structural integrity and overall sturdiness .
They are available in customised lengths that range from 2mtrs upto 10mtrs,which allows for easy installation and compatibility with various roof sizes
The common widths of these roofing sheets are 860mm and 1200mm.these dimensions provide flexibility for different roof designs and layouts
~Imarikah Roofing sheets come in various thickness,   gauge 32 ,gauge 30 and  gauge 28.with a lower gauge indicating thicker sheets.
They are available in both matte/rough texture and glossy/smooth texture. Which also describes finishing.
Imarikah sheets are ideal for weather-resistant roofing. They have a warranty of upto 15yrs.
            "
          </p>
        </div>

        {/* Mission & Vision div */}
        <div className="md:w-1/2 flex flex-col group space-y-2 pt-24">
          <div className="bg-red-400 duration-500 group-hover:scale-[0.85] hover:!scale-100 cursor-pointer p-8 rounded-tl-3xl h-60">
            <h4 className="uppercase text-xl text-white font-bold">Mission</h4>
            <p className="text-lg leading-7 my-3 ">
              "To provide durable iron sheet solutions for lasting structures
              and a sustainable future."
            </p>
          </div>
          <div className="bg-red-400 duration-500 group-hover:scale-[0.85] hover:!scale-100 p-8 rounded-tl-3xl h-60">
            <h4 className="uppercase text-xl text-white font-bold">Vision</h4>
            <p className="text-xl leading-7 my-3 ">
            "Imarikah Roofing Solutions envisions being the  leading provider of innovative and sustainable roofing solutions,
             delivering exceptional quality and service, and maintaining the highest standards of professional integrity."
            </p>
          </div>
        </div>
      </div>
        </div>
      </div>
      {/* About end */}
      <Slider />
      <div className="md:m-16">
        <div className="bg-red-400 duration-500 group-hover:scale-[0.85] hover:!scale-100 p-20 rounded-tl-3xl ">
          <h4 className="uppercase text-xl text-white font-bold">What We Do</h4>
          <p className="text-xl leading-7 my-3 font-semibold">
            "We empower customers by supplying quality roofing materials."
          </p>
          {/* <Link to="boxprofile">
          <button class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded absolute  mb-4 mr-4">
            Our Products
          </button>
          </Link> */}
        </div>
      </div>

    </div>
  );
}
export default About;
