import React from 'react'
import home from "../src/img/roof.jpg";

import Corrugateds from "../src/img/Corrugated/Corrugated.jpeg";
import Corrugated1 from "../src/img/Corrugated/corrugated-glossy.jpeg";
import Corrugated2 from "../src/img/Corrugated/Corrugated3.jpeg";
import Corrugated3 from "../src/img/Corrugated/corrugated-glossy1.jpeg";

import Corrugated4 from "../src/img/Corrugated/corrugated-matte.jpeg";

import { motion } from "framer-motion";


const corrugated = [
    {
      image: Corrugateds,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: Corrugated1,
      name: "Residential building in Parklands",
      description: "Ksh 500M",
    },
    {
      image: Corrugated2,
      name: "Student accommodation in Roysambu",
      description: "Ksh 400M",
    },
    {
      image: Corrugated3,
      name: "Student accommodation in Roysambu",
      description: "Ksh 400M",
    },
  ];

function Corrugated() {

    const homeStyle = {
        backgroundImage: `url(${home})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        filter: "brightness(100%)",
      };


    const duplicatedSlides = [...corrugated, ...corrugated];

  return (
    <div className='font-Nunito bg-gray-50'>
       <div
        className="mx-auto  shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
        style={homeStyle}
      >
            <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
             Corrugated
            </h2>

        </div>
 {/* imarika coruggated glossy */}
 <div className="container mx-auto md:px-12 p-6">
        <div>
          <h2 className="text-red-600 text-4xl font-semibold">

            Imarikah Corrugated Glossy
          </h2>
          <h2 className=" md:text-2xl">
            Imarikah Corrugated Glossy is a timeless sinusoidal profile suitable
            for roofing and cladding applications. Originating from the
            Victorian era, it remains a popular choice for both residential and
            commercial buildings. Its classic design offers a blend of tradition
            and functionality, making it a versatile option for various
            architectural styles
          </h2>
        </div>
        <div className="  bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
          <h1 className="text-2xl md:text-3xl lg:text-4x">
            Corrugated Glossy Prices
          </h1>
          <p className="text-xl g:text-lg xl:text-2xl">
            G.30 Khs 550 <br /> G.28 Khs 700
          </p>
        </div>
        <div className="relative h-full overflow-hidden py-12 bg-white">
          <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent after:filter after:blur-3"></div>

          <motion.div
            className="flex items-center justify-between"
            animate={{
              x: ["0%", "-100%"],
              transition: {
                ease: "linear",
                duration: 15,
                repeat: Infinity,
              },
            }}
          >
            {duplicatedSlides.map((slide, index) => (
              <div key={index} className="flex-shrink-0 mx-4 md:mx-8">
                <div className="flex flex-col items-center">
                  <img
                    src={slide.image}
                    alt={slide.name}
                    className="max-w-72 h-72 rounded-md mb-2 transform transition-transform hover:scale-105"
                  />
                  {/* <h3 className="text-center text-lg font-semibold">{slide.name}</h3> */}
                  {/* <p className="text-center text-sm">{slide.description}</p> */}
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
      {/* imarika coruggated matte */}

      <div className="container mx-auto md:px-12 p-6">
        <div className="flex flex-col md:flex-row items-center justify-center gap-6">
          <div className="md:w-1/2">
            <h2 className="text-red-600 text-4xl font-semibold">
              Imarikah Corrugated Matte
            </h2>
            <p className="md:text-xl">
              Imarikah Corrugated has stylish protection that offers superior
              weather resistance, energy efficiency, and a modern look,
              enhancing your property’s value and longevity. it is available in
              various colours and it's corrugated profile adds elegance and
              sophistication to your roofing need.
            </p>
          </div>
          <img
            src={Corrugated4} // Assuming the first element of the matte array contains the image
            alt=""
            className="max-w-72 h-72 md:w-1/4 md:h-1/8 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
          />
          <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
            <div className="w-full h-full flex flex-col ">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
                Corrugated Matte Prices
              </h1>
              <p className="text-xl g:text-lg xl:text-2xl">
                G.30 Khs 630 <br /> G.28 Khs 730
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
  )
}

export default Corrugated
