import React from 'react'

import home from "../src/img/roof.jpg";

import Ela from "../src/img/ela/ela.jpeg";
import Ela1 from "../src/img/ela/ela1.jpeg";

const ela = [
    {
      image: Ela,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: Ela1,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },

    // {
    //   image: Ela2,
    //   name: "Mixed use building in Kileleshwa",
    //   description: "Ksh 700M",
    // },
  ];

function Elegantile() {

    const homeStyle = {
        backgroundImage: `url(${home})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        filter: "brightness(100%)",
      };


  return (
    <div className='font-Nunito bg-gray-50'>
        <div
    className="mx-auto  shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
    style={homeStyle}
  >
        <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
        Elegantile
        </h2>

    </div>
    <div className="container mx-auto px-12 p-6">
        <h2 className="text-red-600 text-4xl font-semibold">
          Imarikah Elegantile
        </h2>
        <h2 className=" md:text-2xl">
          Imarika Elegantile offers stylish and durable roofing solutions,
          blending sophistication with strength. Crafted from high-quality
          materials and coated for weather resistance, it provides reliable
          protection with a refined aesthetic, perfect for residential and
          commercial buildings alike.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {ela.map((elas, idx) => (
            <div
              key={idx}
              className="max-w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={elas.image}
                alt={elas.name}
                className="w-72 h-72 rounded-tl-3xl"
              />
              {/* <div className="px-6 py-4 bg-red-200">
                <div className="font-bold text-xl mb-2">{shingle.name}</div>
                <p className="text-gray-900 text-lg ">{shingle.description}</p>
              </div> */}
            </div>
          ))}
          <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-6">
            <div className="w-full h-full flex flex-col ">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
                Elegantile Price
              </h1>
              <p className="text-xl g:text-lg xl:text-2xl">
                G.30 Khs 680 <br /> G.28 Khs 780
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Elegantile
