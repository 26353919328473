import React from 'react';

const FlipCard = () => {
  return (
    <div className="flex flex-wrap justify-center gap-4">
  <div className="relative w-72 h-72 bg-gray-300 border border-gray-300 hover:transform hover:scale-105 transition-transform duration-300 p-3">
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">Delivery CountryWide</h1>
      <p className="text-sm md:text-base lg:text-lg xl:text-xl px-4">Enjoy  delivery nationwide, ensuring your products reach you no matter where you are in Kenya.</p>
    </div>
  </div>
  <div className="relative w-72 h-72 bg-gray-300 border border-gray-300 hover:transform hover:scale-105 transition-transform duration-300 p-3">
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">Long Lasting Materials</h1>
      <p className="text-sm md:text-base lg:text-lg xl:text-lg px-4">Our iron sheets, made from premium, durable materials, offer lasting protection against time and weather, safeguarding your property.</p>
    </div>
  </div>
  <div className="relative w-72 h-72 bg-gray-300 border border-gray-300 hover:transform hover:scale-105 transition-transform duration-300 p-3">
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">A Starter Guide</h1>
      <p className="text-sm md:text-base lg:text-lg xl:text-xl px-4">With every purchase, unlock a guide for personal growth and self-improvement, reflecting our commitment to building better futures.</p>
    </div>
  </div>
  <div className="relative w-72 h-72 bg-gray-300 border border-gray-300 hover:transform hover:scale-105 transition-transform duration-300 p-3">
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">24-Hour Support</h1>
      <p className="text-sm md:text-base lg:text-lg xl:text-xl px-4">Our dedicated customer care team is available 24/7 to promptly address your queries and efficiently resolve your concerns.</p>
    </div>
  </div>
</div>


  );
};

export default FlipCard;
