import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nakhungu from "./roof.png";

function Footer() {
  return (
    <div className=" bg-gray-500 font-Nunito">
      <div class="h-2 w-full bg-red-600"></div>
      <div className="md:flex md:justify-between md:items-center lg:px-6 px-3 py-2">
        <div className="space-x-2 ">
          {/* <h6 className="text-red-700 font-bold text-3xl pb-6"> Nakhungu Build Solutons</h6> */}
          <div className=" space-x-4">
            <img src={nakhungu} alt="logo" className="h-52 w-52 " />
          </div>
          {/* <img src={Map} alt="" className="h-72 w-80" /> */}
          {/* <div>
            <Feeds />
          </div> */}
        </div>
        <div className="grid grid-rows-6 grid-flow-col mt-4   ">
          <h2 className="text-white font-bold text-3xl pb-4">MENU</h2>
          <Link
            to="/"
            className="hover:text-red-600 text-white duration-300
          text-xl cursor-pointer leading-6  underline decoration-solid decoration-red-400 underline-offset-8 "
          >
            Home
          </Link>
          <Link
            to="/about"
            className="hover:text-red-600 text-white duration-300
          text-xl cursor-pointer leading-6 underline decoration-solid decoration-red-400 underline-offset-8  "
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="hover:text-red-600 text-white duration-300
          text-xl cursor-pointer leading-6 underline decoration-solid decoration-red-400 underline-offset-8 "
          >
            Contact Us
          </Link>
          <Link
            to="/products"
            className="hover:text-red-600 text-white duration-300
          text-xl cursor-pointer leading-6 underline decoration-solid decoration-red-400 underline-offset-8 "
          >
            Products
          </Link>
        </div>

        <div className="grid grid-rows-6 grid-flow-col mt-4">
          <h3 className="text-white font-bold text-3xl pb-4  ">FIND US</h3>
          <div className="flex space-x-2">
            <FontAwesomeIcon
              icon="fa-solid fa-phone"
              style={{ color: "#e6410a" }}
            />
            <a
              href="tel:+254 717 893 864"
              className="hover:text-red-600 text-white text-xl"
            >
              +254 717 893 864
            </a>
          </div>

          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-envelope"
              style={{ color: "#e6410a" }}
            />
            <a
              href="mailto:info@imarikah.com"
              className="hover:text-red-600 text-white text-xl"
            >
              info@imarikah.com
            </a>
          </div>

          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              style={{ color: "#e6410a" }}
            />
            <a href="" className="hover:text-red-400 text-white text-xl">
              Nairobi
            </a>
          </div>
        </div>

        {/* services */}
        <div className="md:grid md:grid-rows-6 grid-flow-col md:mt-4">
          <h3 className="text-white font-bold text-3xl pb-4  ">

            WE ARE IN SOCIAL
          </h3>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/profile.php?id=61559883117553&mibextid=ZbWKwL" target="_blank">
              <FontAwesomeIcon
                icon="fa-brands fa-facebook"
                style={{ color: "#e6410a" }}
                size="2xl"
              />
            </a>
            <a href="">
              <FontAwesomeIcon
                icon="fa-brands fa-twitter"
                style={{ color: "#e6410a" }}
                size="2xl"
              />
            </a>
            <a href="" target="_blank">
              <FontAwesomeIcon
                icon="fa-brands fa-square-instagram"
                style={{ color: "#e6410a" }}
                size="2xl"
              />
            </a>
          </div>
          <div className="mt-6">
            <h2 className="text-white text-center text-xl"> © Copyright Imarikah. 2024 All Rights Reserved
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
