import React, { useState, Fragment } from "react";
import nakhungu from "./roof.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'; // For Heroicons v2

// Utility function to conditionally join class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Navbar() {
  let [open, setOpen] = useState(false);

  return (
    <div className="bg-gray-400 font-Nunito">
      <nav className="flex items-center justify-between p-1">
        <FontAwesomeIcon
          icon="bars"
          style={{ color: "#000000" }}
          className="md:hidden fixed pr-20 -right-16 pb-3 cursor-pointer h-6 z-20 top-3"
          onClick={() => setOpen(!open)}
        />
        <div className="space-x-4">
          <img src={nakhungu} alt="logo" className="h-32 w-48" />
        </div>

        <ul
          className={`bg-gray-400 backdrop-blur-md md:static fixed duration-500 ease-linear top-12 md:flex md:items-center md:space-x-10 md:h-auto h-screen z-10 ${
            !open ? "right-[-500%]" : "right-0"
          }`}
        >
          <li className="duration-500 pt-10 md:pt-0">
            <a
              href="/"
              className="text-white hover:text-red-400 text-xl cursor-pointer font-Barlow font-bold inline-block md:py-2 p-6"
            >
              Home
            </a>
          </li>
          <li className="duration-500">
            <a
              href="/about"
              className="text-white hover:text-red-400 text-xl cursor-pointer font-Barlow font-bold inline-block md:py-2 p-6"
            >
              About Us
            </a>
          </li>

          <li className="duration-500">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Products
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="boxprofile"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Box Profile
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="corrugated"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                         Corrugated
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="bricktile"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Brick Tiles
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="orientile"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Orientile
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="elegantile"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Elegantile
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="acessories"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Accessories
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="decra"
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Decra Tiles
                        </a>
                      )}
                    </Menu.Item>

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <li className="duration-500">
            <a
              href="/contact"
              className="text-white hover:text-red-400 text-xl cursor-pointer font-Barlow font-bold inline-block md:py-2 p-6"
            >
              Contact
            </a>
          </li>
          <li className="duration-500 bg-black rounded">
            <a
              href="/email"
              className="text-white hover:text-red-400 text-xl cursor-pointer font-Barlow font-bold inline-block md:py-2 p-6"
            >
              Get A Quote
            </a>
          </li>
        </ul>
        <div className="text-xl grid grid-rows-3">
          <div className="flex space-x-2">
            <FontAwesomeIcon
              icon="fa-solid fa-phone"
              size="xs"
              style={{ color: "#e6410a" }}
            />
            <a
              href="tel:+254 717 893 864"
              className="hover:text-red-600 text-white text-xl"
            >
              +254 717 893 864
            </a>
          </div>
          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-envelope"
              size="xs"
              style={{ color: "#e6410a" }}
            />
            <a
              href="mailto:info@imarikah.com"
              className="hover:text-red-600 text-white text-xl"
            >
              info@imarikah.com
            </a>
          </div>
          <div className="flex space-x-4">
            <FontAwesomeIcon
              icon="fa-solid fa-location-dot"
              size="xs"
              style={{ color: "#e6410a" }}
            />
            <a href="https://maps.app.goo.gl/hFdvp5Y6NqBSvpiv8" className="hover:text-red-400 text-white text-xl">
            Embakasi, Nairobi
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
