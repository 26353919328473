import React from 'react'
import { motion } from "framer-motion";

import home from "../src/img/roof.jpg";

import classic from "./Classic tiles.jpeg";
import classic1 from "./Classic.jpeg";
import classic2 from "./classiccc.jpeg";

import shingles from "./Shingles.jpeg";
import shingles1 from "./shingles.jpeg";
import shingles2 from "./shinglesss.jpeg";

import MilanoTiles from "./Milano Tiles.jpeg";
import MilanoTiles1 from "./milano1.jpeg";
import MilanoTiles2 from "./milano2.jpeg";
import MilanoTiles3 from "./milanol.jpeg";

const classictiles = [
    {
      image: classic,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: classic1,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: classic2,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
  ];
  const Shingles = [
    {
      image: shingles,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: shingles2,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    // {
    //   image: shingles1,
    //   name: "Mixed use building in Kileleshwa",
    //   description: "Ksh 700M",
    // },
  ];
  const milanoTiles = [
    {
      image: MilanoTiles,
      name: "",
      description: "",
    },
    {
      image: MilanoTiles1,
      name: "",
      description: "",
    },
    {
      image: MilanoTiles2,
      name: "",
      description: "",
    },
    {
      image: MilanoTiles3,
      name: "",
      description: "",
    },
  ];

function Decra() {

    const homeStyle = {
        backgroundImage: `url(${home})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        filter: "brightness(100%)",
      };

      const milano = [...milanoTiles, ...milanoTiles];


  return (
    <div className='font-Nunito bg-gray-50'>
         <div
        className="mx-auto shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
        style={homeStyle}
      >
        <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
          Decra
        </h2>
      </div>

    {/* Milano */}
    <div className="container mx-auto px-12 p-6">
        <div>
          <h2 className="text-red-600 text-4xl font-semibold">
            Imarikah Milano Tiles
          </h2>
          <h2 className=" md:text-2xl">
            Imarikah Milano Tiles: Milano stone-coated steel roof tile is one of
            the mainstream roof tiles. The warm colors and unique shapes make
            stone-coated metal roof tile widely used in families. Classic colors
            and shapes match the unique walls of the house. Imarikah Milano has
            up to 50 years warranty and are available in both gauge 26 and 28*.
          </h2>
        </div>

        <div className="  bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
          <h1 className="text-2xl md:text-3xl lg:text-4x">
          Milano Tiles Prices
          </h1>
          <p className="text-xl g:text-lg xl:text-2xl">
            Khs 750
          </p>
        </div>

        <div className="relative h-full overflow-hidden py-12 bg-white">
          <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent after:filter after:blur-3"></div>

          <motion.div
            className="flex items-center justify-between"
            animate={{
              x: ["0%", "-100%"],
              transition: {
                ease: "linear",
                duration: 15,
                repeat: Infinity,
              },
            }}
          >
            {milano.map((milanos, index) => (
              <div key={index} className="flex-shrink-0 mx-4 md:mx-8">
                <div className="flex flex-col items-center">
                  <img
                    src={milanos.image}
                    alt={milanos.name}
                    className="max-w-72 h-72 rounded-md mb-2 transform transition-transform hover:scale-105"
                  />
                  {/* <h3 className="text-center text-lg font-semibold">{slide.name}</h3> */}
                  {/* <p className="text-center text-sm">{slide.description}</p> */}
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>

      {/* Classic tiles */}
      <div className="container mx-auto px-12 p-6">
        <h2 className="text-red-600 text-4xl font-semibold">
          Imarikah Classic Tiles
        </h2>
        <h2 className=" md:text-2xl">
          Imarika Classic Tiles present a timeless roofing option, combining
          enduring style with practicality. These iron sheets feature a classic
          tile design, offering a traditional look while ensuring durability and
          weather resistance. Imarika Classic Tiles are versatile, suitable for
          various architectural styles and building types, providing both charm
          and reliability for residential and commercial projects. Imarikah
          Classic Tiles has up to 50 years warranty and are available in both
          gauge 26 and 28*.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {classictiles.map((classic, idx) => (
            <div
              key={idx}
              className="max-w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={classic.image}
                alt={classic.name}
                className="w-72 h-72 rounded-tl-3xl"
              />
              {/* <div className="px-6 py-4 bg-red-200">
                <div className="font-bold text-xl mb-2">{classic.name}</div>
                <p className="text-gray-900 text-lg ">{classic.description}</p>
              </div> */}
            </div>
          ))}
            <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-6">
            <div className="w-full h-full flex flex-col ">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
              Classic Tiles Price
              </h1>
              <p className="text-xl g:text-lg xl:text-2xl">
                 Khs 750
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Shingles */}
      <div className="container mx-auto px-12 p-6">
        <h2 className="text-red-600 text-4xl font-semibold">
          Imarikah Shingles
        </h2>
        <h2 className=" md:text-2xl">
          Imarikah Shingles combine elegance and durability, offering
          long-lasting protection with style. Crafted from high-quality
          materials, they enhance the beauty and resilience of any
          structure.Imarikah Shingles has up to 50 years warranty and are
          available in both gauge 26 and 28*.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Shingles.map((shingle, idx) => (
            <div
              key={idx}
              className="max-w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={shingle.image}
                alt={shingle.name}
                className="w-72 h-72 rounded-tl-3xl"
              />
              {/* <div className="px-6 py-4 bg-red-200">
                <div className="font-bold text-xl mb-2">{shingle.name}</div>
                <p className="text-gray-900 text-lg ">{shingle.description}</p>
              </div> */}
            </div>
          ))}
          <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-6">
            <div className="w-full h-full flex flex-col ">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
              Shingles Price
              </h1>
              <p className="text-xl g:text-lg xl:text-2xl">
                 Khs 750
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Decra
