import React, { useState } from "react";

import { motion } from "framer-motion";

import home from "../src/img/roof.jpg";



import RollTop from "../src/acessories/rolltops.jpeg";
import RollTop1 from "../src/acessories/rooltops.jpeg";
import RoofingNail from "../src/acessories/rofingnails.jpeg";
import One from "../src/acessories/1.jpeg";
import Two from "../src/acessories/2.jpeg";
import Three from "../src/acessories/3.jpeg";
import Four from "../src/acessories/4.jpeg";
import DrilingNail from "../src/acessories/drillingnails.jpeg";




const acessorie = [
  {
    image: RollTop,
    name: "Rolltops",
    description: "Ksh 700",
  },
  {
    image: RollTop1,
    name: "Rolltops",
    description: " Ksh 700",
  },

  {
    image: RoofingNail,
    name: "Roofing Nail",
    description: "Ksh 280",
  },

  {
    image: One,
    name: "Ridge Cap",
    description: "Ksh 600",
  },
  {
    image: Two,
    name: "Valleys",
    description: "Ksh 600",
  },

  {
    image: Three,
    name: "Roofing Nails",
    description: "Ksh 280",
  },
  {
    image: Four,
    name: "Rubber Washers",
    description: "Ksh 150",
  },

  {
    image: DrilingNail,
    name: "Drilling Nails",
    description: "Ksh 850",
  },
];


function Projects() {
  const homeStyle = {
    backgroundImage: `url(${home})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    filter: "brightness(100%)",
  };


  return (
    <div className="font-Nunito bg-gray-50">
      {/* <div
        className="mx-auto  shadow-md overflow-hidden font-Nunito bg-gray-200 h-[600px]"
        style={homeStyle}
      >
        <div className="">
          <div className=" md:w-full md:h-screen justify-center items-center backdrop-brightness-50 bg-black/30 p-14 md:pt-40 space-y-4">
            <h2 className="uppercase md:tracking-wide text-4xl text-center text-red-500 font-semibold">
              Products
            </h2>
            <h1 className="uppercase text-center tracking-wide md:text-4xl text-white font-semibold">
              "Delivering iron sheet solutions that meet your every construction
              need."
            </h1>
          </div>
        </div>
      </div> */}
      {/* <div className="p-6">
        <h2 className=" md:tracking-wide md:text-4xl text-center font-normal">
          Imarikah Roofing Solutions offers a wide range <br />
          of iron sheet solutions for residential and commercial <br />
          construction, ensuring durability and customization options to <br />
          meet your specific needs.
        </h2>
      </div> */}
 <div
        className="mx-auto shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
        style={homeStyle}
      >
        <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
        Accessorie
        </h2>
      </div>

      {/* Accessories  */}
      <div className="container mx-auto px-12 p-12">
        <div>
          <h2 className="text-red-600 text-4xl font-semibold">
            Imarikah Accessories
          </h2>
        </div>
        <br />
        {/* <h2 className=" md:text-2xl">
        Imarika Elegantile offers stylish and durable roofing solutions, blending sophistication with strength. Crafted from high-quality materials and coated for weather resistance, it provides reliable protection with a refined aesthetic, perfect for residential and commercial buildings alike.
        </h2> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {acessorie.map((acessories, idx) => (
            <div
              key={idx}
              className=" grid grid-cols-1 md:grid-cols-2 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={acessories.image}
                alt={acessories.name}
                className="w-56 h-40 rounded-tl-3xl"
              />
              <div>
                <h3 className=" text-lg font-semibold p-5">
                  {acessories.name}
                </h3>
                <p className="text-lg  p-5 ">{acessories.description}</p>
              </div>

              {/* <div className="px-6 py-4 bg-red-200">
                <div className="font-bold text-xl mb-2">{shingle.name}</div>
                <p className="text-gray-900 text-lg ">{shingle.description}</p>
              </div> */}
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}

export default Projects;
