import React from 'react';
import { motion } from 'framer-motion';
import Briks from "./brik.jpeg";
import Classics from "./classic1.jpeg";
import Glossy from "../src/img/Corrugated/corrugated-glossy.jpeg";
import MatteBox from "../src/img/Box-pro/imarika-box-matte.jpeg";
import Box from "../src/img/Box-pro/Box profile .jpeg";
import Corrugated from "../src/img/Corrugated/corrugated-matte.jpeg";
import Milano from "./milano2.jpeg";
import Orientile from "../src/img/orientile/orientile2.jpeg";
import Ela from "../src/img/ela/ela1.jpeg";
import Shingles from "./shingles.jpeg";

const orientile = [
  { image: Briks, name: "Imarikah Brick tiles", description: "Raising: Ksh 700M" },
  { image: Classics, name: "Imarikah Classic Tiles", description: "Raising: Ksh 700M" },
  { image: Glossy, name: "Imarikah Corrugated Glossy", description: "Raising: Ksh 700M" },
  { image: MatteBox, name: "Imarika Box Profile Matte", description: "Raising: Ksh 700M" },
  { image: Box, name: "Imarikah Box Profile Glossy ", description: "Raising: Ksh 700M" },
  { image: Corrugated, name: "Imarikah Corrugated Matte", description: "Raising: Ksh 700M" },
  { image: Milano, name: "Imarikah Milano Tiles", description: "Raising: Ksh 700M" },
  { image: Orientile, name: "Imarika Orientile", description: "Raising: Ksh 700M" },
  { image: Ela, name: "Imarika Elegantile ", description: "Raising: Ksh 700M" },
  { image: Shingles, name: "Imarikah Shingles", description: "Raising: Ksh 700M" },
];

const SliderDesign2 = () => {
  const duplicatedSlides = [...orientile, ...orientile];

  return (
    <div className="relative h-full overflow-hidden py-12 bg-white">
      <div className="absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent after:filter after:blur-3"></div>

      <motion.div
        className="flex items-center justify-between"
        animate={{
          x: ['0%', '-100%'],
          transition: {
            ease: 'linear',
            duration: 15,
            repeat: Infinity,
          }
        }}
      >
        {duplicatedSlides.map((slide, index) => (
          <div key={index} className="flex-shrink-0 mx-4 md:mx-8">
            <div className="flex flex-col items-center">
              <img src={slide.image} alt={slide.name} className="max-w-full h-72 rounded-md mb-2" />
              <h3 className="text-center text-lg font-semibold">{slide.name}</h3>
              {/* <p className="text-center text-sm">{slide.description}</p> */}
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default SliderDesign2;
