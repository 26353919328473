import react from "react";
import Map from "./map.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie";
import chatbot from "./chatbot.json";
import EmailForm from "./EmailForm";

function Contact() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: chatbot,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="font-Nunito">
      <div className=" md:flex md:justify-between md:items-center ">
        <div className="md:px-12 p-4 md:flex md:flex-col space-y-4">
          <h3 className="text-red-500 text-4xl font-bold">Contact Us</h3>
          <p className="text-3xl text-black font-normal">
            Welcome to Imarikah, where we are committed to providing <br />
            top-quality iron sheet products and exceptional customer <br />
            service. If you have any questions,need assistance, or want <br />
            to learn more about our extensive range of iron sheet offerings,
            you're in the right place. <br />
            Our Contact Page is your gateway to connecting with us.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="">
            <Lottie
              animationData={chatbot}
              options={defaultOptions}
              height={500}
              max-width={500}
              classNameName=""
            />
          </div>
        </div>
      </div>

        <div className="md:grid md:grid-cols-2 ">
          <div className="md:w-[450] lg:p-4 md:p-4 group space-y-2 ">
            {/* <!--first card --> */}
            <div className="">
              <div className="bg-red-400 duration-500 group-hover:scale-[0.85] hover:!scale-100 cursor-pointer p-8 rounded-tl-3xl">
                <a href="info@imarikah.com">
                  <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    Email or Call US Through
                  </h5>
                </a>
                <div className="flex space-x-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    style={{ color: " #000000" }}
                  />
                  <a
                    href="tel:+254 717 893 864"
                    className="hover:text-red-600 text-2xl"
                  >
                    +254 717 893 864
                  </a>
                </div>

                <div className="flex space-x-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-envelope"
                    style={{ color: " #000000" }}
                  />
                  <a
                    href="mailto:info@imarikah.com"
                    className="hover:text-red-600 md:text-2xl"
                  >
                    info@imarikah.com
                  </a>
                </div>

                <div className="flex space-x-4">
                  <FontAwesomeIcon
                    icon="fa-solid fa-location-dot"
                    style={{ color: " #000000" }}
                  />
                  <a href="" className="hover:text-red-600  text-2xl">
                    Nairobi
                  </a>
                </div>
              </div>
            </div>

            {/* <!-- second card --> */}
            <div className="">
              <div className="bg-red-400 duration-500 group-hover:scale-[0.85] hover:!scale-100 cursor-pointer p-8 rounded-tl-3xl">
                <a href="#">
                  <h2 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    Where We are Located
                  </h2>
                </a>
                <h3 className="mb-3 font-normal  text-2xl">
                Embakasi, Nairobi
                </h3>
                <h3 className="mb-3 font-normal   text-2xl">Anywhere Around Nairobi</h3>
                {/* <h3 className="mb-3 font-normal   text-2xl">Nairobi Kenya</h3> */}
              </div>
            </div>
          </div>
          <div>
          <img
            src={Map}
            alt=""
            className="w-full h-full "
          />
        </div>


      </div>



    </div>
  );
}
export default Contact;
