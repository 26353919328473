import React from 'react'

import home from "../src/img/roof.jpg";


import Real from "../src/img/Box-pro/Box profile .jpeg";
import Real1 from "../src/img/Box-pro/Box profile 2.jpeg";
import Real2 from "../src/img/Box-pro/Box profile1.jpeg";

import { motion } from "framer-motion";

import box2 from "../src/img/Box-pro/imarika-box-matte.jpeg";
// import box1 from "./box-profile-matte-2.jpeg";

const box = [
  {
    image: Real,
    name: "Box profile sheets are some of the toughest and most versatile roofing materials available",
    description: "Ksh 700M",
  },
  {
    image: Real1,
    name: "Residential building in Parklands",
    description: "Ksh 500M",
  },
  {
    image: Real2,
    name: "Student accommodation in Roysambu",
    description: "Ksh 400M",
  },
];

function BoxProfile() {

  const homeStyle = {
    backgroundImage: `url(${home})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    filter: "brightness(100%)",
  };

  return (
    <div className='font-Nunito bg-gray-50'>
       <div
        className="mx-auto  shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
        style={homeStyle}
      >
<h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
             Box Profile
            </h2>
      </div>
      {/* box profile glossy */}
    <div className="container mx-auto px-12 p-6 grid grid-cols-1 md:grid-cols-2">
      <div className='grid grid-cols-1 md:grid-cols-1'>
        <div>
        <h2 className="text-red-600 text-4xl font-semibold">
        Imarikah Box Profile Glossy
      </h2>
      <h2 className=" md:text-2xl">
        Box Profile Glossy sheets are angular, trapezoidal profiles made from
        high-tensile Alu-Zinc coated steel. They're widely used in roofing and
        wall cladding for both domestic and industrial applications. With a
        robust design and modern aesthetic, they offer a sleek alternative to
        traditional corrugated metal sheets. Available in various colors, they
        provide durability, weather resistance, and easy installation.
      </h2>
        </div>

      <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
          <div className="w-full h-full flex flex-col ">
            <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
              Box Profile Glossy Prices
            </h1>
            <p className="text-xl g:text-lg xl:text-2xl">
              G.30 Khs 550 <br /> G.28 Khs 700
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        {box.map((boxs, idx) => (
          <div
            key={idx}
            className="w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
          >
            <img
              src={boxs.image}
              alt={boxs.name}
              className="w-72 h-72 rounded-tl-3xl"
            />
            {/* <div className="px-6 py-4 bg-red-200">
              <div className="font-bold text-xl mb-2">{boxs.name}</div>
              <p className="text-gray-900 text-lg ">{boxs.description}</p>
            </div> */}
          </div>
        ))}

      </div>
    </div>

    {/* box profile matte */}
    <div className="container mx-auto px-12 p-6">
      <div className="flex flex-col md:flex-row items-center justify-center gap-6">
        <div className="md:w-1/2">
          <h2 className="text-red-600 text-4xl font-semibold">
            Imarikah Box Profile Matte
          </h2>
          <p className="md:text-xl">
            Imarika Box Profile Matte is a stylish and durable roofing and
            wall cladding material crafted from high-quality Alu-Zinc coated
            steel sheets. Its matte finish offers a modern aesthetic appeal,
            making it ideal for residential, commercial, and industrial
            applications. With excellent weather resistance and low
            maintenance requirements, it provides both functionality and
            elegance to any architectural design. Available in various colors,
            its angular profile adds structural integrity, making it a
            versatile choice for construction projects seeking a sleek and
            sophisticated look.
          </p>
        </div>
        <img
          src={box2} // Assuming the first element of the matte array contains the image
          alt=""
          className="max-w-72 h-72 md:w-1/4 md:h-1/8 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
        />
        <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
          <div className="w-full h-full flex flex-col ">
            <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
              Box Profile Matte Prices
            </h1>
            <p className="text-xl g:text-lg xl:text-2xl">
              G.30 Khs 630 <br /> G.28 Khs 730
            </p>
          </div>
        </div>
      </div>
    </div></div>
  )
}

export default BoxProfile
