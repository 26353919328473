import React from 'react'

import home from "../src/img/roof.jpg";

import Brick0 from "./brik.jpeg";
import Brick1 from "./brik1.jpeg";
import Brick2 from "./brik2.jpeg";

const brick = [
    {
      image: Brick0,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
    {
      image: Brick1,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },

    {
      image: Brick2,
      name: "Mixed use building in Kileleshwa",
      description: "Ksh 700M",
    },
  ];

function BrickTile() {
    const homeStyle = {
        backgroundImage: `url(${home})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        filter: "brightness(100%)",
      };

  return (
    <div className='font-Nunito bg-gray-50'>
    <div
     className="mx-auto  shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
     style={homeStyle}
   >
         <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
          Brick tiles
         </h2>

     </div>
        {/* Brick tiles */}
    <div className="container mx-auto px-12 p-6">
      <h2 className="text-red-600 text-4xl font-semibold">
        Imarikah Brick tiles
      </h2>
      <h2 className=" md:text-2xl">
        Imarika Brick tiles are iron sheets designed to mimic the appearance
        of traditional brickwork. These durable and weather-resistant sheets
        offer the timeless charm of brick construction while being lightweight
        and easy to install. Ideal for roofing, wall cladding, and decorative
        purposes, Imarika Brick tiles provide a classic aesthetic for both
        residential and commercial buildings, blending rustic appeal with
        modern convenience.
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {brick.map((bricks, idx) => (
          <div
            key={idx}
            className="max-w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
          >
            <img
              src={bricks.image}
              alt={bricks.name}
              className="w-72 h-72 rounded-tl-3xl"
            />
            {/* <div className="px-6 py-4 bg-red-200">
              <div className="font-bold text-xl mb-2">{classic.name}</div>
              <p className="text-gray-900 text-lg ">{classic.description}</p>
            </div> */}
          </div>
        ))}
        <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 hover:transform hover:scale-105 transition-transform duration-300 p-3">
          <div className="w-full h-full flex flex-col ">
            <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
              Brick tiles Prices
            </h1>
            <p className="text-xl g:text-lg xl:text-2xl">
              G.30 Khs 650 <br /> G.28 Khs 750
            </p>
          </div>
        </div>
      </div>
    </div></div>
  )
}

export default BrickTile
