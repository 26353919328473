import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    reply_to: '',
    message: '',
    product: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('Imarikah', 'imarikah', e.target, 'EqcZYu-YAH_yLqp33')
      .then((result) => {
          setStatus('Message sent successfully!');
      }, (error) => {
          setStatus('Failed to send the message, please try again.');
      });

    setFormData({
      name: '',
      reply_to: '',
      message: '',
      product: '',
    });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 font-Nunito">
      <form className="bg-white p-6 rounded shadow-md w-full max-w-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-center">Get A Quote</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            name="reply_to"
            id="email"
            value={formData.reply_to}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="product">Product</label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="product"
            id="product"
            value={formData.product}
            onChange={handleChange}
            required
          >
            <option value="" disabled>Select a product</option>
            <option value="Box Profile">Box Profile</option>
            <option value="Corrugated">Corrugated</option>
            <option value="Brick Tiles">Brick Tiles</option>
            <option value="Orientile">Orientile</option>
            <option value="Elegantile">Elegantile</option>
            <option value="Accessories">Accessories</option>
            <option value="Decra Tiles">Decra Tiles</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Send
          </button>
        </div>
        {status && (
          <div className="mt-4 text-center">
            <p className={`text-sm ${status.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>{status}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default EmailForm;
