import React from 'react';
import home from "../src/img/roof.jpg";
import OrientileImg from "../src/img/orientile/orientile.jpeg";
import Orientile2Img from "../src/img/orientile/orientile2.jpeg";
import OriImg from "../src/img/ela/ela2.jpeg";

const orient = [
  {
    image: OrientileImg,
    name: "Mixed use building in Kileleshwa",
    description: "Ksh 700M",
  },
  {
    image: Orientile2Img,
    name: "Mixed use building in Kileleshwa",
    description: "Ksh 700M",
  },
  {
    image: OriImg,
    name: "Mixed use building in Kileleshwa",
    description: "Ksh 700M",
  },
];

function  Orientile (){
  const homeStyle = {
    backgroundImage: `url(${home})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    filter: "brightness(100%)",
  };

  return (
    <div className="font-Nunito bg-gray-50">
      <div
        className="mx-auto shadow-md overflow-hidden font-Nunito bg-gray-200 h-52"
        style={homeStyle}
      >
        <h2 className="uppercase md:tracking-wide text-4xl text-center pt-20 text-red-500 font-semibold">
          Orientile
        </h2>
      </div>

      {/* Imarika Orientile */}
      <div className="container mx-auto px-12 p-6">
        <h2 className="text-red-600 text-4xl font-semibold">
          Imarikah Orientile
        </h2>
        <h2 className="md:text-2xl">
          Imarika Orientile represents a premium roofing solution characterized
          by its elegant shingle-like design. Crafted from high-quality
          materials, these roofing sheets offer both style and durability,
          providing a sophisticated look while ensuring long-lasting protection
          for your property. Imarika Orientile is ideal for residential,
          commercial, and industrial applications, offering versatility and
          reliability for various architectural styles and construction
          projects.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {orient.map((item, idx) => (
            <div
              key={idx}
              className="max-w-72 rounded overflow-hidden shadow-lg transform transition-transform hover:scale-105"
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-72 h-72 rounded-tl-3xl"
              />
              {/* Uncomment below if you need to show the name and description */}
              {/* <div className="px-6 py-4 bg-red-200">
                <div className="font-bold text-xl mb-2">{item.name}</div>
                <p className="text-gray-900 text-lg ">{item.description}</p>
              </div> */}
            </div>
          ))}
          <div className="relative w-72 h-72 bg-gray-200 border border-gray-200 transform hover:scale-105 transition-transform duration-300 p-6">
            <div className="w-full h-full flex flex-col">
              <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-red-600 pb-4">
                Orientile Price
              </h1>
              <p className="text-xl lg:text-lg xl:text-2xl">
                G.30 Ksh 650 <br /> G.28 Ksh 750
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orientile;
